document.addEventListener("turbo:load", function () {
    document.querySelectorAll("div[data-filter-section]").forEach(function(item) {
        item.addEventListener("click", function(event) {
          event.preventDefault()
          filtersNode = document.getElementById(item.attributes["data-filter-section"].value);
          filtersNode.classList.toggle('hidden');
          if (filtersNode.classList.contains('hidden')) {
              // Down Caret
              item.innerHTML =  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-5 w-5 icon-lg inline-block" role="img" aria-labelledby="a6suoy6jfyc9zu4sm7pz90pnipdnac3x"><title id="a6suoy6jfyc9zu4sm7pz90pnipdnac3x">Icons/caret</title><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>'
          } else {
            // Left Caret    
            // item.innerHTML =  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-5 w-5 icon-lg inline-block" role="img" aria-labelledby="a4yvx9h534thk65f6pymk2n145mv4l5e"><title id="a4yvx9h534thk65f6pymk2n145mv4l5e">Icons/cheveron left</title><path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"></path></svg>'
              
            // Up Caret
            item.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-5 w-5 icon-lg inline-block" role="img" aria-labelledby="aej562uh29auotba5a1nooxseq1p897v"><title id="aej562uh29auotba5a1nooxseq1p897v">Icons/cheveron up</title><path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z"></path></svg>'
          }
          
        })      
    })

})




