document.addEventListener("turbo:load", function () {

    var generatedPublicId = `player-${document.getElementById("player_id").value.match(/\d/g)}-${Math.floor(Math.random() * 1000000)}`;

    var cloudinaryWidget = cloudinary.createUploadWidget({
        cloudName: "aca-media",
        sources: ["local"],
        publicId: generatedPublicId,
        showCompletedButton: true,
        uploadPreset: "video-unsigned"}, (error, result) => {
            if (!error && result && result.event === "success") {
                console.log("Done! Here is the image info: ", result.info);

                let formData = new FormData()

                formData.append("player_id", document.getElementById("player_id").value.match(/\d/g));
                formData.append("upload_url", `video-clips/${generatedPublicId}`);

                fetch(`/videos`, {
                    body: formData,
                    method: 'POST',
                    data: { action: 'ajax:success->editor#createSuccess' },
                    // dataType: 'script',
                    // credentials: "include",
                    headers: {
                        "X-CSRF-Token": getMetaValue("csrf-token")
                    }
                })
                .then(function(response) {        
                    // Check to see which div to replace
                    container = document.getElementById('highlights_editor')
                    console.log(container)
                    console.log(response)
                    console.log(response.editor)
                    console.log("Renderer#render js controller this is what is called.")

                    // create and dispatch the event
                    // var reloadEditor = new CustomEvent("load-editor", {
                    //     detail: {
                    //         editorHtml: response.editor
                    //     }
                    // });
                    // window.dispatchEvent(reloadEditor);

                    // const event = new CustomEvent("load-editor")
                    // window.dispatchEvent(event)


                });

                function getMetaValue(name) {
                    const element = document.head.querySelector(`meta[name="${name}"]`)
                    return element.getAttribute("content")
                };

            }
        }
    );

    document.getElementById("new-clip-button").addEventListener("click", function() {
        cloudinaryWidget.open();
    }, false);

});

