import Choices from 'choices.js'

const createCollegesSelect = function () {
  
    var collegesSelect = document.getElementById('note[college_id]')
  
    // Passing options (with default options)
    const colleges = new Choices(collegesSelect, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 3,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => {},
        placeholder: true,
        placeholderValue: "",
        searchPlaceholderValue: "",
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: '+',
        addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
        return `Only ${maxItemCount} values can be added`;
        },
        valueComparer: (value1, value2) => {
        return value1 === value2;
        },
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: 'choices__item',
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          selectedState: 'is-selected',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fusepion
        fuseOptions: {
        include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates: null
    });
  
}

const createPlayersSelect = function () {
  
    var playersSelect = document.getElementById('note[player_id]')
  
    // Passing options (with default options)
    const players = new Choices(playersSelect, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 7,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => {},
        placeholder: true,
        placeholderValue: "",
        searchPlaceholderValue: "",
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: '+',
        addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
        return `Only ${maxItemCount} values can be added`;
        },
        valueComparer: (value1, value2) => {
        return value1 === value2;
        },
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: 'choices__item',
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          selectedState: 'is-selected',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fusepion
        fuseOptions: {
        include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates: null
    });
  playersSelect.addEventListener(
    'change',
    function (event) {
      var note_value = players.getValue(true)
      if(note_value.includes('player') || note_value.findIndex(element => element.includes("player"))>=0 ){
        // playersSelect.name = 'note[player_id][]'
        disableEnablePlayerTeam('team');
      } else if(note_value.findIndex(element => element.includes("team"))>=0){
        // playersSelect.name = 'note[team_id][]'
        disableEnablePlayerTeam('player');
      }
    },
    false,
  );
}

function disableEnablePlayerTeam(field) {
  setTimeout(function(){
    var a = document.querySelectorAll('[data-value^="'+ field +'"]')
    a.forEach(function(e){
      e.classList.remove("choices__item--selectable");
      e.classList.add("choices__item--disabled");
      e.style.cssText = "opacity: 0.1; pointer-events: none";
    });
  }, 5);
}
document.addEventListener("turbo:load", function () {
  createCollegesSelect()
  createPlayersSelect()
  if (window.location.pathname.includes('edit')) {
    disableEnablePlayerTeam('team')
  }else{
    disableEnablePlayerTeam('')
  }
})
