import noUiSlider from 'nouislider'
import wNumb from 'wnumb'



document.addEventListener("turbo:before-cache", function() {
    // ...
    var enrollmentSlider = document.getElementById('enrollmentSlider');
    var rightSizeMin = document.getElementById('right_size_min');
    var rightSizeMax = document.getElementById('right_size_max');

    var satSlider = document.getElementById('satSlider');

    var actSlider = document.getElementById('actSlider');

    var fullCostSlider = document.getElementById('fullCostSlider');

    // var netPriceSlider = document.getElementById('netPriceSlider');


    enrollmentSlider.noUiSlider.destroy();
    satSlider.noUiSlider.destroy();
    actSlider.noUiSlider.destroy();
    fullCostSlider.noUiSlider.destroy();
    // netPriceSlider.noUiSlider.destroy();
})

document.addEventListener("turbo:load", function () {
    
    var createSlider;
    createSlider = function(sliderElement, startHandleValue, endHandleValue, lowValueID, highValueID, step, decimalCount, prefix, margin) {
        
        var collegeFilterForm = document.getElementById('college_filter');
    
        var submitFilterForm;
        submitFilterForm = function() {
            Rails.fire(collegeFilterForm, "submit")
        }
        
        var updateSliderValue;

        updateSliderValue = function(slider, handle, prefix) {

            var moneyFormat = wNumb({
                thousand: ',',
                prefix: '$',
                decimals: decimalCount
            });

            var basicFormat = wNumb({
                decimals: decimalCount
            });

            var largeFormat = wNumb({
                thousand: ',',
                decimals: decimalCount
            });

            var children, i, results, val, values;
            if (handle == null) {
                handle = 0;
            }
            children = slider.getElementsByClassName('noUi-handle');
            values = slider.noUiSlider.get();
            i = 0;
            results = [];
            while (i < children.length) {
                if (children.length === 1) {
                val = parseFloat(values);
                } else {
                val = parseFloat(values[i]);
                }
                if (prefix === '$') {
                    children[i].dataset.value = moneyFormat.to(val);
                } else if (val >= 10000) {
                    children[i].dataset.value = largeFormat.to(val);
                } else {
                    children[i].dataset.value = basicFormat.to(val);
                }
                results.push(i++);
            }
            return results;
        };
        noUiSlider.create(sliderElement, {
            start: [document.getElementById(lowValueID).value, document.getElementById(highValueID).value],
            connect: true,
            step: step,
            margin: margin,
            range: {
                'min': startHandleValue,
                'max': endHandleValue
            },
            format: wNumb({
                decimals: decimalCount,
                mark: '.'
            }),
            tooltips: false,
            pips: false
            // {
            //     mode: 'positions',
            //     values: [0, 25, 50, 75, 100],
            //     density: 4,
            //     stepped: true,
            //     format: wNumb({
            //         decimals: 0,
            //     })
            // }
        });

        // const event = new CustomEvent(startHandleValue);
    
        sliderElement.noUiSlider.on('set', function (values) {
            var lowValueInput = document.getElementById(lowValueID);
            var highValueInput = document.getElementById(highValueID);
            lowValueInput.setAttribute('value', parseFloat(values[0]));
            highValueInput.setAttribute('value', parseFloat(values[1]));

            // lowValueInput.addEventListener(`${lowValueID}-set`, function(e) { console.log("custom event heard") });
            // highValueInput.addEventListener(`${highValueID}-set`, function(e) { console.log("custom event heard") });

            // create and dispatch the event
            var lowValueEvent = new CustomEvent(`${lowValueID}-set`, {});
            var highValueEvent = new CustomEvent(`${lowValueID}-set`, {});

            lowValueInput.dispatchEvent(lowValueEvent);
            highValueInput.dispatchEvent(highValueEvent);

        });
    
        sliderElement.noUiSlider.on('update', function() {
            return updateSliderValue(sliderElement,null,prefix,decimalCount);
        });

    }

    // create enrollment slider
    var enrollmentSlider = document.getElementById('enrollmentSlider');
    createSlider(enrollmentSlider, 0, 60000, 'right_size_min', 'right_size_max', 500, 0, '', 1000);

    // create sat slider
    var satSlider = document.getElementById('satSlider');
    createSlider(satSlider, 600, 1600, 'sat_range_low', 'sat_range_high', 100, 0, '', 200);

    // create act slider
    var actSlider = document.getElementById('actSlider');
    createSlider(actSlider, 0, 36, 'act_range_low', 'act_range_high', 1, 0, '', 7);

    // create gpa slider
    var gpaSlider = document.getElementById('gpaSlider');
    createSlider(gpaSlider, 0, 5.0, 'gpa_range_low', 'gpa_range_high', 0.1, 1, '');

    // create full_cost slider
    var fullCostSlider = document.getElementById('fullCostSlider');
    createSlider(fullCostSlider, 0, 80000, 'coa_range_low', 'coa_range_high', 2000, 0, '$', 10000);

    // create net_price slider
    // var netPriceSlider = document.getElementById('netPriceSlider');
    // createSlider(netPriceSlider, 0, 60000, 'net_price_range_low', 'net_price_range_high', 2000, 0, '$', 10000);

    // HIDDEN UNTIL DATA AVAILABLE

    // // create toefl slider
    // var toeflSlider = document.getElementById('toeflSlider');
    // createSlider(toeflSlider, 10, 100, 'toefl_range_low', 'toefl_range_high', 10, 0, '');

    // // create team_strength slider
    // var teamStrengthSlider = document.getElementById('teamStrengthSlider');
    // createSlider(teamStrengthSlider, 0, 10, 'team_strength_range_low', 'team_strength_range_high', 1, 1, '');
})